<template>
	<div class="laptop">

		<div class="laptop__screen">
			<Images width="100%" height="100%" align="top" v-if="image" :image="image" />
		</div>
		<div class="laptop__top"></div>
		<div class="laptop__bottoml"></div>
		<div class="laptop__bottom"></div>
		<div class="laptop__trackl"></div>
		<div class="laptop__track"></div>
		<div class="laptop__base"></div>

	</div>
</template>

<script>
export default {
	props: {
		image: String,
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.laptop{
	position: relative;
	padding-bottom: 60%;
	width: 100%;
}
.laptop__screen{
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border: 2px solid #9F6F73;
	@include breakpoint(sm){
		left: 20%;
		top: 14%;
		width: 61%;
		height: 59%;
	}
}
.laptop__top{
	@include breakpoint(sm){
		position: absolute;
		border: 2px solid #9F6F73;
		left: 18%;
		top: 10%;
		width: 65%;
		height: 66%;
		border-radius: 10px 10px 0 0;
	}
}
.laptop__bottoml {
	@include breakpoint(sm){
		position: absolute;
		border: 3px solid #9F6F73;
		border-bottom: 2px solid #9F6F73;
		border-top: none;
		border-right: none;
		left: 13.3%;
		top: 76.5%;
		width: 65%;
		height: 15%;
		transform: skewX(-45deg);
		border-radius: 0 0 0 10px;
	}
}
.laptop__bottom {
	@include breakpoint(sm){
		position: absolute;
		border: 3px solid #9F6F73;
		border-bottom: 2px solid #9F6F73;
		border-top: none;
		border-left:none;
		left: 22.7%;
		top: 76.5%;
		width: 65%;
		height: 15%;
		transform: skewX(45deg);
		border-radius: 0 0 10px 0;
	}
}
.laptop__trackl {
	@include breakpoint(sm){
		position: absolute;
		border: 3px solid #9F6F73;
		border-bottom: 2px solid #9F6F73;
		border-top: none;
		border-right: none;
		left: 23.3%;
		top: 76.5%;
		width: 45%;
		height: 8%;
		transform: skewX(-45deg);
		border-radius: 0 0 0 10px;
	}
}
.laptop__track {
	@include breakpoint(sm){
		position: absolute;
		border: 3px solid #9F6F73;
		border-bottom: 2px solid #9F6F73;
		border-top: none;
		border-left:none;
		left: 32.7%;
		top: 76.5%;
		width: 45%;
		height: 8%;
		transform: skewX(45deg);
		border-radius: 0 0 10px 0;
	}
}
.laptop__base{
	@include breakpoint(sm){
		position: absolute;
		border: 2px solid #9F6F73;
		border-top: none;
		left: 9.6%;
		top: 91.5%;
		width: 81.5%;
		height: 2%;
		border-radius: 0 0 10px 10px;
	}
}
</style>

<!-- Used in : AR -->